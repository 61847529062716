/* eslint-disable @typescript-eslint/no-non-null-assertion */
// import VueCookies from "vue-cookies";
import mixins from "../mixin";

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import LoginView from "../views/LoginView.vue";
import FormView from "../views/FormView.vue";
import NocDashboardView from "../views/NocDashboardView.vue";
import NocApprovalView from "../views/NocApprovalView.vue";
import NocDeactivateView from "../views/NocDeactivateView.vue";
import NocReportView from "../views/NocReportView.vue";
import NocUsersView from "../views/UsersView.vue";
import ITHeadDashboardView from "../views/ITHeadDashboardView.vue";
import HODDashboardView from "../views/HODDashboardView.vue";
import SupportDashboardView from "../views/SupportApprovalView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    meta: { title: "Wifi Access Login", roles: [] },
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    meta: { title: "Wifi Access Form", roles: ["user"] },
    path: "/",
    name: "form",
    component: FormView,
  },
  {
    meta: { title: "NOC Dashboard", roles: ["noc"] },
    path: "/noc-dashboard",
    name: "nocdashboard",
    component: NocDashboardView,
  },
  {
    meta: { title: "NOC Approval", roles: ["noc"] },
    path: "/noc-approval",
    name: "nocapproval",
    component: NocApprovalView,
  },
  {
    meta: { title: "NOC Deactivate", roles: ["noc"] },
    path: "/noc-deactivate",
    name: "nocdeactivate",
    component: NocDeactivateView,
  },
  {
    meta: { title: "NOC Reports", roles: ["noc"] },
    path: "/noc-report",
    name: "nocreport",
    component: NocReportView,
  },
  {
    meta: { title: "Users", roles: ["noc"] },
    path: "/noc-users",
    name: "nocusers",
    component: NocUsersView,
  },
  {
    meta: { title: "IT Head Dashboard", roles: ["ithead"] },
    path: "/ithead-dashboard",
    name: "itheaddashboard",
    component: ITHeadDashboardView,
  },
  {
    meta: { title: "HOD Dashboard", roles: ["hod"] },
    path: "/hod-dashboard",
    name: "hoddashboard",
    component: HODDashboardView,
  },
  {
    meta: { title: "Support Dashboard", roles: ["support"] },
    path: "/support-dashboard",
    name: "supportdashboard",
    component: SupportDashboardView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const cookie = Vue.$cookies.get(mixins.data().cookieKey);
  if (cookie == null && to.name == "form") {
    document.title = to.meta.title;
    next();
  } else if (cookie == null && to.name != "login") {
    router.push("/login");
  } else if (cookie != null && to.name == "login") {
    if (cookie.user.role == "hod") {
      router.push("/hod-dashboard");
    } else if (cookie.user.role == "ithead") {
      router.push("/ithead-dashboard");
    } else if (cookie.user.role == "noc") {
      router.push("/noc-dashboard");
    } else if (cookie.user.role == "support") {
      router.push("/support-dashboard");
    }
  } else {
    document.title = to.meta.title;
    if (to.meta.roles.length == 0 || to.meta.roles.includes(cookie.user.role)) {
      next();
      // eslint-disable-next-line no-dupe-else-if
    } else if (!to.meta.roles.includes(cookie.user.role)) {
      next("/login");
    }
  }
});
export default router;
