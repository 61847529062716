<style>
.col-card {
  padding: 30px !important;
}

.col-card-title {
  padding: 20px 30px !important;
  font-size: 20px;
}

h1 {
  text-align: center;
  width: 100%;
  font-size: xxx-large;
  margin-bottom: 10px;
  letter-spacing: 7px;
  color: maroon;
}
</style>
<template>
  <v-container>
    <v-row align="center" justify="center" style="height: 91vh" dense>
      <v-card class="ma-auto vw-wh" elevation="2" style="padding: 30px">
        <v-row no-gutters>
          <h1>NOC CONFIGURATION</h1>
          <!-- <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="10"
            class="elevation-3"
            style="margin: 0 30px; width: 100%; color: maroon"
            :loading="loadTable"
            loading-text="Loading..."
            :footer-props="{
              'items-per-page-options': [10],
            }"
          > -->
          <v-data-table
            fixed-header
            :headers="headers"
            :items="data"
            style="margin: 0 30px; width: 100%; color: maroon"
            height="300"
            :loading="loadTable"
            loading-text="Loading..."
            item-value="name"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                rounded="pill"
                :color="snackbarColor ? 'green' : 'red'"
                style="margin-top: 20px"
                top
              >
                {{ snackbarText }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <v-dialog
                v-model="configDialog"
                persistent
                max-width="400"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="forConfig(item)"
                    small
                  >
                    <v-icon small style="margin-right: 10px">
                      fa-solid fa-check
                    </v-icon>
                    Done Config
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    class="text-h5 primary"
                    style="color: white; padding-bottom: 15px"
                  >
                    Config by whom?
                  </v-card-title>
                  <v-card-text style="margin-top: 20px; padding-bottom: 0">
                    <v-text-field
                      outlined
                      name="configBy"
                      label="Config By"
                      v-model="configBy"
                      :rules="rules.configby"
                      required
                      rea
                    ></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="configDialog = false"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="configRequest(forConfigData.id)"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <!-- <v-icon small @click="declineRequest(item)">fa-solid fa-x</v-icon> -->
              </v-dialog>
            </template>
          </v-data-table>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "employee_name", align: "center" },
        { text: "Mobile Number", value: "mobile_number", align: "center" },
        { text: "Designation", value: "designation", align: "center" },
        { text: "Account", value: "account", align: "center" },
        { text: "Mac Address", value: "mac_address", align: "center" },
        { text: "Device", value: "device_type", align: "center" },
        { text: "Purpose", value: "purpose", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      loadTable: false,
      loading: false,
      data: [],
      configDialog: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: false,
      timeout: 3000,
      configBy: "",
      rules: {
        configby: [
          (val) => (val || "").length > 0 || "Configuration By is required",
        ],
      },
      forConfigData: {
        id: 0,
        account: "",
        designation: "",
        device_type: "",
        employee_name: "",
        mac_address: "",
        mobile_number: "",
        purpose: "",
        status: "",
        role: "",
        accounts: "none",
      },
      timer: "",
      approvalAccounts: "",
    };
  },
  created() {
    this.role = this.userData.role;
    this.timer = setInterval(this.getList, 300000);
    this.configBy = this.userData.fullname;
    this.approvalAccounts = this.userData.approval_account;
    this.getList();
  },
  methods: {
    getList() {
      this.loadTable = true;
      var formData = new FormData();
      formData.append("role", this.role);
      formData.append("accounts", "none");
      formData.append("approvalAccounts", this.approvalAccounts);
      axios.post(this.apiUrl + "/for/approval", formData).then((response) => {
        this.data = response.data;
        setTimeout(() => {
          this.loadTable = false;
        }, 1000);
      });
    },
    forConfig(item) {
      this.forConfigData = Object.assign({}, item);
      this.configDialog = true;
    },
    configRequest(id) {
      var formData = new FormData();
      formData.append("id", id);
      formData.append("config_by", this.configBy);
      formData.append("status", "CONFIGURED BY NOC");

      axios.post(this.apiUrl + "/done/by-noc", formData).then((response) => {
        if (response.status == 200) {
          this.snackbar = true;
          this.snackbarText = "Configuration Done!";
          this.snackbarColor = "green";
          this.loading = false;
          this.loadTable = true;
          this.configDialog = false;
          this.getList();
        }
      });
    },
    closeConfig() {
      this.configDialog = false;
      this.forConfigData = {};
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
};
</script>
