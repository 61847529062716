<style>
.col-card-title {
  /* padding: 10px 20px !important; */
  font-size: 20px;
}

h1 {
  margin-bottom: 0 !important;
}

h2 {
  margin-bottom: 0 !important;
  /* font-size: xx-large !important; */
  letter-spacing: normal !important;
  color: maroon;
  font-size: 1.5vw !important;
}

.v-application--is-ltr .v-data-footer__pagination {
  margin-left: auto;
}

.vw-wh-noc-dashboard {
  max-width: 100vw !important;
  min-width: 90vw !important;
  width: 90vw !important;
  height: 70vh !important;
}
</style>

<template>
  <v-container>
    <v-row align="center" justify="center" style="height: 91vh" dense>
      <v-card
        class="ma-auto vw-wh-noc-dashboard"
        elevation="2"
        style="padding: 3%; padding-top: 2%"
      >
        <v-row no-gutters>
          <h1>NOC DASHBOARD</h1>
          <v-col cols="12" sm="4">
            <v-card elevation="2" class="col-card-title mr-5 mb-2">
              <v-row>
                <v-col cols="8" sm="9">
                  <h2 style="text-align: left">Pending</h2>
                </v-col>
                <v-col cols="4" sm="3">
                  <h2 style="text-align: right">{{ pendingCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card elevation="2" class="col-card-title mr-5 mb-2">
              <v-row>
                <v-col cols="8" sm="10">
                  <h2 style="text-align: left">HOD Approved</h2>
                </v-col>
                <v-col cols="4" sm="2">
                  <h2 style="text-align: right">{{ hodCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card elevation="2" class="col-card-title mb-2">
              <v-row>
                <v-col cols="8" sm="10">
                  <h2 style="text-align: left">IT Head Approved</h2>
                </v-col>
                <v-col cols="4" sm="2">
                  <h2 style="text-align: right">{{ itheadCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <v-card elevation="2" class="col-card-title mr-5 mb-5">
              <v-row>
                <v-col cols="8" sm="10">
                  <h2 style="text-align: left">NOC Configured</h2>
                </v-col>
                <v-col cols="4" sm="2">
                  <h2 style="text-align: right">{{ nocCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card elevation="2" class="col-card-title mr-5 mb-5">
              <v-row>
                <v-col cols="8" sm="9">
                  <h2 style="text-align: left">Done</h2>
                </v-col>
                <v-col cols="4" sm="3">
                  <h2 style="text-align: right">{{ doneCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card elevation="2" class="col-card-title mr-5 mb-5">
              <v-row>
                <v-col cols="8" sm="8">
                  <h2 style="text-align: left">Declined</h2>
                </v-col>
                <v-col cols="4" sm="4">
                  <h2 style="text-align: right">{{ declineCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card elevation="2" class="col-card-title mb-5">
              <v-row>
                <v-col cols="8" sm="8">
                  <h2 style="text-align: left">Deactive</h2>
                </v-col>
                <v-col cols="4" sm="4">
                  <h2 style="text-align: right">{{ deactCount }}</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <!-- <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            class="elevation-3"
            style="width: 100%; color: maroon"
            :footer-props="{
              'items-per-page-options': [5],
            }"
            :loading="loadTable"
            loading-text="Loading..."
            dense
          > -->
          <v-data-table
            fixed-header
            :headers="headers"
            :items="data"
            style="margin: 0 30px; width: 100%; color: maroon"
            height="300"
            :loading="loadTable"
            loading-text="Loading..."
            item-value="name"
            dense
          ></v-data-table>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
      headers: [
        { text: "Name", value: "employee_name", align: "center" },
        { text: "Designation", value: "designation", align: "center" },
        { text: "Account", value: "account", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Date Created", value: "date_created", align: "center" },
        { text: "HOD Approved By", value: "hod_approved", align: "center" },
        { text: "IT Head Assigned", value: "approval_user", align: "center" },
        { text: "Config By", value: "config_by", align: "center" },
        { text: "Config Date", value: "config_date", align: "center" },
      ],
      pendingCount: 0,
      hodCount: 0,
      itheadCount: 0,
      nocCount: 0,
      declineCount: 0,
      deactCount: 0,
      doneCount: 0,
      loadTable: false,
      timer: "",
      approvalAccounts: "",
    };
  },
  created() {
    this.getCount();
    this.getPending();
    this.timer = setInterval(this.getPending, 300000);
  },
  methods: {
    getCount() {
      axios.get(this.apiUrl + "/getCount").then((response) => {
        this.pendingCount = response.data.pending;
        this.hodCount = response.data.hod;
        this.itheadCount = response.data.ithead;
        this.nocCount = response.data.noc;
        this.declineCount = response.data.decline;
        this.deactCount = response.data.deact;
        this.doneCount = response.data.done;
      });
    },
    getPending() {
      this.loadTable = true;
      axios.get(this.apiUrl + "/for/getPending").then((response) => {
        this.data = response.data;
        console.log(this.data);
        setTimeout(() => {
          this.loadTable = false;
        }, 1000);
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
};
</script>
