<style>
.vw-wh-users {
  max-width: 100vw !important;
  min-width: 90vw !important;
  width: 90vw !important;
  height: 80vh !important;
}

.v-tab--active {
  color: #b71c1c !important;
  background: white;
  border-radius: 20px 20px 0 0;
}

.v-tab {
  color: white;
}

h1 {
  margin-top: 10px;
  margin-bottom: 0 !important;
  font-size: 3vw !important;
}

.px-xs {
  max-height: 9vh !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none;
}
</style>
<template>
  <v-container>
    <v-row align="center" justify="center" style="height: 91vh" dense>
      <v-card
        class="ma-auto vw-wh-users"
        elevation="2"
        style="
          padding: 10px;
          width: 90vw;
          background: #b71c1c !important;
          border-radius: 20px;
        "
      >
        <template>
          <!-- <v-tabs v-model="tab" fixed-tabs background-color="red darken-4" dark>
            <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs> -->
          <v-tabs v-model="tab" fixed-tabs background-color="red darken-4" dark>
            <v-tab v-for="item in items" :key="item.id">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            style="height: 69vh; border-radius: 0 0 20px 20px"
          >
            <!-- CREATE USER TAB ITEM  -->
            <v-tab-item>
              <h1>CREATE USER</h1>
              <v-form
                ref="form"
                v-model="isFormValid"
                lazy-validation
                @submit.prevent="submit"
              >
                <v-container style="max-width: 45vw !important">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="fullname"
                        :rules="rules.fullname"
                        label="Full Name"
                        required
                        outlined
                        prepend-inner-icon="mdi-account"
                        class="px-xs"
                        color="indianred"
                      ></v-text-field>
                      <v-text-field
                        v-model="email"
                        :rules="rules.email"
                        label="Email"
                        type="email"
                        required
                        outlined
                        prepend-inner-icon="mdi-at"
                        class="px-xs"
                        color="indianred"
                        @input="checkEmail"
                        :disabled="emailValidation"
                      ></v-text-field>
                      <v-text-field
                        v-model="username"
                        :rules="rules.username"
                        label="Username"
                        required
                        outlined
                        prepend-inner-icon="mdi-account-check"
                        class="px-xs"
                        color="indianred"
                        @input="checkUsername"
                        :disabled="usernameValidation"
                      ></v-text-field>
                      <v-select
                        v-model="account"
                        :items="accounts"
                        chips
                        label="Choose Account"
                        multiple
                        outlined
                        prepend-inner-icon="mdi-store"
                        class="px-xs"
                        v-if="itheadRole || hodRole"
                        :readonly="itheadRole"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="site"
                        :rules="rules.site"
                        :items="sites"
                        label="Choose Site"
                        required
                        outlined
                        prepend-inner-icon="mdi-map-marker"
                        class="px-xs"
                      ></v-select>
                      <v-select
                        v-model="role"
                        :rules="rules.role"
                        :items="roles"
                        label="Choose Roles"
                        outlined
                        prepend-inner-icon="mdi-tooltip-account"
                        class="px-xs"
                        @change="onChangeRole"
                        required
                      ></v-select>
                      <v-select
                        v-if="itheadRole"
                        v-model="itheadApproval"
                        :items="accounts"
                        chips
                        label="Approval Account/s"
                        multiple
                        outlined
                        prepend-inner-icon="mdi-account-multiple"
                        required
                      ></v-select>
                    </v-col>
                    <div class="text-center" style="width: 100%">
                      <v-btn
                        color="success"
                        class="mr-4 ma-auto"
                        type="submit"
                        :loading="loading"
                        :disabled="!isFormValid"
                      >
                        Submit
                      </v-btn>
                      <v-snackbar
                        v-model="snackbar"
                        :timeout="timeout"
                        rounded="pill"
                        :color="snackColor ? 'primary' : 'accent'"
                        style="margin-top: 20px"
                        top
                      >
                        {{ snackbarText }}

                        <template v-slot:action="{ attrs }">
                          <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                          >
                            Close
                          </v-btn>
                        </template>
                      </v-snackbar>
                      <v-btn color="error" class="mr-4 ma-auto" @click="reset">
                        Reset
                      </v-btn>
                    </div>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
            <!-- SET HOD -->
            <v-tab-item>
              <h1>SET HOD</h1>
              <!-- <v-data-table
                :headers="headers"
                :items="hodList"
                :items-per-page="5"
                class="elevation-3"
                style="margin: 0 30px; color: maroon"
                :loading="loading"
                loading-text="Loading..."
                :footer-props="{
                  'items-per-page-options': [5],
                }"
              > -->
              <v-data-table
                fixed-header
                :headers="headers"
                :items="hodList"
                style="margin: 0 30px; color: maroon"
                height="300"
                :loading="loadTable"
                loading-text="Loading..."
                item-value="name"
              >
                <template v-slot:top>
                  <v-dialog
                    v-model="editDialog"
                    max-width="700px"
                    :retain-focus="false"
                  >
                    <v-card>
                      <v-card-title
                        class="text-h5 primary"
                        style="color: white; padding-bottom: 15px"
                      >
                        Edit User
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row style="margin: auto">
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.fullname"
                                label="Fullname"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.email"
                                label="Email"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.username"
                                label="Username"
                                outlined
                                readonly
                              ></v-text-field>
                              <v-select
                                v-model="editedItem.site"
                                :items="sites"
                                label="Site"
                                required
                                outlined
                              ></v-select>
                              <v-select
                                v-model="editedItem.account"
                                :items="accounts"
                                chips
                                label="Account/s"
                                multiple
                                outlined
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="editDialog = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveEditedItem"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
            <!-- SET IT HEAD -->
            <v-tab-item>
              <h1>SET IT HEAD</h1>
              <!-- <v-data-table
                :headers="headers"
                :items="itHeadList"
                :items-per-page="5"
                class="elevation-3"
                style="margin: 0 30px; color: maroon"
                :loading="loading"
                loading-text="Loading..."
                :footer-props="{
                  'items-per-page-options': [5],
                }"
              > -->
              <v-data-table
                fixed-header
                :headers="headers"
                :items="itHeadList"
                style="margin: 0 30px; color: maroon"
                height="300"
                :loading="loadTable"
                loading-text="Loading..."
                item-value="name"
              >
                <template v-slot:top>
                  <v-dialog
                    v-model="editDialog"
                    max-width="700px"
                    :retain-focus="false"
                  >
                    <v-card>
                      <v-card-title
                        class="text-h5 primary"
                        style="color: white; padding-bottom: 15px"
                      >
                        Edit User
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row style="margin: auto">
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedItem.fullname"
                                label="Fullname"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.email"
                                label="Email"
                                outlined
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.username"
                                label="Username"
                                outlined
                                readonly
                              ></v-text-field>
                              <v-select
                                v-model="editedItem.site"
                                :items="sites"
                                label="Site"
                                required
                                outlined
                              ></v-select>
                              <v-select
                                v-model="editedItem.account"
                                :items="accounts"
                                chips
                                label="Account/s"
                                multiple
                                outlined
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="editDialog = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveEditedItem"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-tab-item>
            <!-- LIST OF USERS -->
            <v-tab-item>
              <h1>LIST OF USERS</h1>
              <!-- <v-data-table
                :headers="headersList"
                :items="allList"
                :items-per-page="5"
                class="elevation-3"
                style="margin: 0 30px; color: maroon"
                :loading="loading"
                loading-text="Loading..."
                :footer-props="{
                  'items-per-page-options': [5],
                }"
              > -->
              <v-data-table
                fixed-header
                :headers="headersList"
                :items="allList"
                style="margin: 0 30px; color: maroon"
                height="300"
                :loading="loadTable"
                loading-text="Loading..."
                item-value="name"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn icon color="error" @click="editRole(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <template v-slot:top>
                  <v-dialog
                    v-model="editRoleDialog"
                    max-width="700px"
                    :retain-focus="false"
                  >
                    <v-card>
                      <v-card-title
                        class="text-h5 primary"
                        style="color: white; padding-bottom: 15px"
                      >
                        Edit Role
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row style="margin: auto">
                            <v-col cols="12">
                              <v-text-field
                                v-model="editedRole.fullname"
                                label="Fullname"
                                outlined
                                readonly
                              ></v-text-field>
                              <v-text-field
                                v-model="editedRole.email"
                                label="Email"
                                outlined
                                readonly
                              ></v-text-field>
                              <v-select
                                v-model="editedRole.role"
                                :items="roles"
                                label="Role"
                                outlined
                                required
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="editRoleDialog = false">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="updateRole">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-tab-item>

            <!-- PARAMETERS -->
            <v-tab-item>
              <h1>PARAMETERS</h1>
              <!-- <v-data-table
                :headers="paramHeaders"
                :items="paramsData"
                :items-per-page="5"
                class="elevation-3"
                style="margin: 0 30px; color: maroon"
                :loading="loading"
                loading-text="Loading..."
                :footer-props="{
                  'items-per-page-options': [5],
                }"
              > -->
              <v-data-table
                fixed-header
                :headers="paramHeaders"
                :items="paramsData"
                style="margin: 0 30px; color: maroon"
                height="300"
                :loading="loadTable"
                loading-text="Loading..."
                item-value="name"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-dialog v-model="newParamDialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Add Parameter
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          class="text-h5 primary"
                          style="color: white; padding-bottom: 15px"
                        >
                          Add New Parameter
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row style="margin: auto">
                              <v-col cols="12">
                                <v-text-field
                                  v-model="newParam.title"
                                  label="Title"
                                  outlined
                                ></v-text-field>
                                <v-select
                                  v-model="newParam.assigned_ithead"
                                  :items="paramITHeadList"
                                  label="Assign IT Head"
                                  outlined
                                ></v-select>
                                <v-select
                                  v-model="newParam.assigned_hod"
                                  :items="paramHodList"
                                  chips
                                  label="Assign HOD"
                                  outlined
                                  multiple
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="newParamDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="saveNewParam"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import vueCookies from "vue-cookies";
export default {
  data() {
    return {
      loading: false,
      isInvalid: false,
      isFormValid: false,
      fullname: "",
      email: "",
      username: "",
      account: [],
      site: "",
      role: "",
      roles: [],
      sites: [],
      accounts: [],
      itheadApproval: [],
      tab: null,
      items: [
        { tab: "Create User", id: "create-user" },
        { tab: "Set HOD", id: "set-hod" },
        { tab: "Set IT Head", id: "set-ithead" },
        { tab: "List of Users", id: "list-users" },
        { tab: "Parameters", id: "parameters" },
      ],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
      rules: {
        fullname: [(val) => (val || "").length > 0 || "Full Name is required"],
        email: [(val) => (val || "").length > 0 || "Email is required"],
        username: [(val) => (val || "").length > 0 || "Username is required"],
        account: [(val) => (val || "").length > 0 || "Account is required"],
        site: [(val) => (val || "").length > 0 || "Site is required"],
        role: [(val) => (val || "").length > 0 || "Role is required"],
      },
      snackbar: false,
      timeout: 3000,
      snackbarText: "",
      snackColor: true,
      usernameValidation: false,
      emailValidation: false,
      checkerReset: false,
      itheadRole: false,
      hodRole: false,
      headers: [
        { text: "Full Name", value: "fullname", align: "center" },
        { text: "Email", value: "email", align: "center" },
        { text: "Username", value: "username", align: "center" },
        { text: "Account", value: "approval_account", align: "center" },
        { text: "site", value: "site", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      hodList: [],
      headersList: [
        { text: "Full Name", value: "fullname", align: "center" },
        { text: "Email", value: "email", align: "center" },
        { text: "Username", value: "username", align: "center" },
        { text: "Account", value: "approval_account", align: "center" },
        { text: "Site", value: "site", align: "center" },
        { text: "Role", value: "role", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      allList: [],
      itHeadList: [],
      paramHeaders: [
        { text: "Type", value: "type", align: "center" },
        { text: "Title", value: "title", align: "center" },
        { text: "Assigned HOD", value: "hod", align: "center" },
        { text: "Assigned ITHead", value: "ithead", align: "center" },
      ],
      paramsData: [],
      addAccount: {
        title: "",
        hod: [],
        ithead: [],
      },
      addAccounDialog: false,
      currentSite: "",
      editDialog: false,
      editedItem: {
        id: 0,
        fullname: "",
        email: "",
        username: "",
        account: [],
        site: "",
        role: "",
      },
      newParamDialog: false,
      paramHodList: [],
      paramITHeadList: [],
      newParam: {
        title: "",
        assigned_hod: [],
        assigned_ithead: "",
      },
      editRoleDialog: false,
      editedRole: {
        id: 0,
        fullname: "",
        email: "",
        role: "",
      },
    };
  },
  created() {
    this.currentSite = this.userData.site;
    this.getAccounts();
    this.getSites();
    this.getRoles();
    this.getHodList();
    this.getAllList();
    this.getITHeadList();
    this.getParam();
  },
  methods: {
    getAccounts() {
      axios.get(this.apiUrl + "/getAccounts").then((response) => {
        this.accountsData = response.data;
        this.accounts = [];
        for (let eachApplication of this.accountsData) {
          this.accounts.push(eachApplication.title);
        }
      });
    },
    getSites() {
      axios.get(this.apiUrl + "/getSites").then((response) => {
        this.sitesData = response.data;
        this.sites = [];
        for (let eachApplication of this.sitesData) {
          this.sites.push(eachApplication.title);
        }
      });
    },
    getRoles() {
      axios.get(this.apiUrl + "/getRoles").then((response) => {
        this.rolesData = response.data;
        this.roles = [];
        for (let eachApplication of this.rolesData) {
          this.roles.push(eachApplication.title);
        }
      });
    },
    checkEmail() {
      if (this.checkerReset == false) {
        if (this.email == null) {
          this.emailValidation = false;
          this.isFormValid = true;
        } else {
          var formData = new FormData();
          formData.append("email", this.email);
          axios.post(this.apiUrl + "/checkEmail", formData).then((response) => {
            if (response.data == 1) {
              this.snackbar = true;
              this.snackbarText = "Email already exist!";
              this.emailValidation = true;
              this.isFormValid = false;
              setTimeout(() => {
                this.email = "";
                this.emailValidation = false;
                this.isFormValid = true;
              }, 2000);
            }
          });
        }
      }
    },
    checkUsername() {
      if (this.checkerReset == false) {
        if (this.username == null) {
          this.usernameValidation = false;
          this.isFormValid = true;
        } else {
          var formData = new FormData();
          formData.append("username", this.username);
          axios
            .post(this.apiUrl + "/checkUsername", formData)
            .then((response) => {
              if (response.data == 1) {
                this.snackbar = true;
                this.snackbarText = "Username already exist!";
                this.usernameValidation = true;
                this.isFormValid = false;
                setTimeout(() => {
                  this.username = "";
                  this.usernameValidation = false;
                  this.isFormValid = true;
                }, 2000);
              }
            });
        }
      }
    },
    submit() {
      // console.log(this.$refs.form.validate());
      // this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        var formData = new FormData();
        formData.append("fullname", this.fullname);
        formData.append("email", this.email);
        formData.append("username", this.username);
        formData.append("account", this.account);
        formData.append("site", this.site);
        formData.append("role", this.role);
        formData.append("approval_ithead", this.itheadApproval);
        axios
          .post(this.apiUrl + "/create/newaccount", formData)
          .then((response) => {
            // console.log(response);
            if (response.data.status == true) {
              this.snackbar = true;
              this.snackbarText = "Submitted Created New User!";
              this.snackColor = true;
            }
            requestAnimationFrame(() => {
              this.itheadRole = false;
              this.hodRole = false;
              this.$refs.form.reset();
            });
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          });
      }
    },
    getHodList() {
      var formData = new FormData();
      formData.append("site", this.currentSite);
      axios.post(this.apiUrl + "/getHodList", formData).then((response) => {
        this.hodList = response.data;
        for (let eachHOD of this.hodList) {
          this.paramHodList.push(eachHOD.fullname.toUpperCase());
        }
        // console.log("hod: ", this.paramHodList);
        setTimeout(() => {
          this.loadTable = false;
        }, 1000);
      });
    },
    editItem(item) {
      var accounts = item.approval_account.split(",");
      // console.log(accounts);
      this.editedItem.account = [];

      this.editedItem.id = item.id;
      this.editedItem.account = accounts;
      this.editedItem.fullname = item.fullname;
      this.editedItem.username = item.username;
      this.editedItem.email = item.email;
      this.editedItem.site = item.site;
      this.editedItem.role = item.role;
      this.editDialog = true;
      // console.log(this.editedItem);
      // this.editDialog = true;
    },

    saveEditedItem() {
      var formData = new FormData();
      formData.append("id", this.editedItem.id);
      formData.append("fullname", this.editedItem.fullname);
      formData.append("email", this.editedItem.email);
      formData.append("username", this.editedItem.username);
      formData.append("account", this.editedItem.account);
      formData.append("site", this.editedItem.site);
      formData.append("role", this.editedItem.role);
      axios.post(this.apiUrl + "/saveEditedItem", formData).then((response) => {
        if (response.data) {
          this.editDialog = false;
          if (this.editedItem.role == "hod") {
            this.getHodList();
          } else {
            this.getITHeadList();
          }

          this.getAccounts();
          this.getSites();
          this.getRoles();
          this.getAllList();
          this.getParam();
        }
      });
    },

    getAllList() {
      axios.get(this.apiUrl + "/getAllUsers").then((response) => {
        console.log(this.apiUrl);
        this.allList = response.data;
      });
    },

    getITHeadList() {
      var formData = new FormData();
      formData.append("site", this.currentSite);
      axios.post(this.apiUrl + "/getITHeadList", formData).then((response) => {
        this.itHeadList = response.data;
        for (let eachITHead of this.itHeadList) {
          this.paramITHeadList.push(eachITHead.fullname.toUpperCase());
        }
        // console.log("ithead: ", this.paramITHeadList);
        setTimeout(() => {
          this.loadTable = false;
        }, 1000);
      });
    },

    getParam() {
      axios.get(this.apiUrl + "/getParam").then((response) => {
        // this.paramList = response.data;
        this.paramsData = [];
        this.params = response.data;
        for (let eachParam of this.params) {
          var ithead = "";
          var hod = "";
          for (let assign of eachParam.assign) {
            if (assign.role == "hod") {
              if (hod != "") {
                hod = hod + ", " + assign.fullname;
              } else {
                hod = assign.fullname;
              }
            } else if (assign.role == "ithead") {
              if (ithead != "") {
                ithead = ithead + ", " + assign.fullname;
              } else {
                ithead = assign.fullname;
              }
            }
          }
          this.paramsData.push({
            id: eachParam.id,
            title: eachParam.title,
            type: eachParam.type,
            hod: hod,
            ithead: ithead,
          });
        }
      });
    },

    saveNewParam() {
      var formData = new FormData();
      formData.append("title", this.newParam.title);
      formData.append("hod", this.newParam.assigned_hod);
      formData.append("ithead", this.newParam.assigned_ithead);
      axios.post(this.apiUrl + "/saveNewParam", formData).then((response) => {
        if (response.data) {
          this.newParamDialog = false;
          this.getAccounts();
          this.getSites();
          this.getRoles();
          this.getAllList();
          this.getParam();
        }
      });
    },

    onChangeRole(selectObj) {
      if (selectObj == "ITHEAD") {
        this.account = [];
        this.itheadRole = true;
        this.hodRole = true;
        this.account.push("IT");
      } else if (selectObj == "HOD") {
        this.itheadRole = false;
        this.hodRole = true;
        this.account = [];
      } else {
        this.itheadRole = false;
        this.hodRole = false;
        this.account = [];
      }
    },

    editRole(item) {
      this.editedRole.id = item.id;
      this.editedRole.fullname = item.fullname;
      this.editedRole.email = item.email;
      this.editedRole.role = item.role.toUpperCase();
      this.editRoleDialog = true;
    },

    updateRole() {
      var formData = new FormData();
      formData.append("role", this.editedRole.role);
      formData.append("id", this.editedRole.id);
      axios.post(this.apiUrl + "/updateUserRole", formData).then((res) => {
        console.log(`test123`, res);
        this.getAllList();
        this.editRoleDialog = false;
      });
    },

    reset() {
      this.checkerReset == true;
      this.isFormValid = true;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>
