<style>
.vw-wh-login {
  max-width: 80vw !important;
  min-width: 35vw !important;
  width: 35vw !important;
  height: 60vh !important;
}
</style>
<template>
  <v-container class="login-page" style="padding: 0">
    <v-row align="center" justify="center" style="height: 100vh" dense>
      <v-card class="ma-auto vw-wh-login" style="border-radius: 10px">
        <v-card-actions style="padding: 4vw; display: block">
          <v-img
            alt="SGCC Logo"
            class="shrink mr-2"
            contain
            src="../assets/wifi_access_v3.png"
            transition="scale-transition"
            width="100vw"
          />
          <v-form @submit.prevent="submit">
            <p
              class="text-red"
              v-if="isInvalid"
              style="text-align: center; color: red"
            >
              *Invalid Credentials
            </p>
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              label="Username"
              required
              outlined
              prepend-inner-icon="mdi-account"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              outlined
              prepend-inner-icon="mdi-lock"
              @click:append="show1 = !show1"
            ></v-text-field>

            <div class="text-center">
              <v-btn
                :disabled="loading"
                :loading="loading"
                class="mr-4 ma-auto"
                type="submit"
                style="background: maroon; color: white"
                elevation="3"
              >
                submit
              </v-btn>
            </div>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import vueCookies from "vue-cookies";
export default {
  data() {
    return {
      show1: false,
      username: "",
      password: "",
      isInvalid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      usernameRules: [(v) => !!v || "username is required"],
      loginMessage: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      this.isInvalid = false;
      axios
        .post(this.apiUrl + "/auth/login/attempt", formData)
        .then((res) => {
          this.loginMessage = "* " + res.data + " *";
          if (res.data.status) {
            vueCookies.set(this.cookieKey, {
              user: res.data,
            });
            setTimeout(() => {
              this.loading = false;
              location.reload();
            }, 1000);
          } else {
            this.isInvalid = true;
            this.loading = false;
            // this.isSubmitted = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
