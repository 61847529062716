<style>
.col-card {
  padding: 30px !important;
}

.col-card-title {
  padding: 20px 30px !important;
  font-size: 20px;
}

h1 {
  text-align: center;
  width: 100%;
  font-size: xxx-large;
  margin-bottom: 10px;
  letter-spacing: 7px;
  color: maroon;
}

.v-col {
  height: 60px;
}
</style>

<template>
  <v-container>
    <v-row align="center" justify="center" style="height: 91vh" dense>
      <v-card class="ma-auto vw-wh" elevation="2" style="padding: 30px">
        <v-row no-gutters>
          <h1>REQUEST REPORTS</h1>
          <v-col
            cols="12"
            sm="2"
            style="height: 60px; margin-right: 10px; margin-left: 10px"
          >
            <v-text-field
              v-model="datefrom"
              label="Date From"
              type="date"
              required
              outlined
              prepend-inner-icon="mdi-calendar"
              class="px-xs mb-4"
              color="indianred"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" style="height: 60px; margin-right: 10px">
            <v-text-field
              v-model="dateto"
              label="Date To"
              type="date"
              required
              outlined
              prepend-inner-icon="mdi-calendar"
              class="px-xs mb-4"
              color="indianred"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" style="height: 60px; margin-right: 10px">
            <v-select
              :items="statusArray"
              label="Status"
              v-model="searchStatus"
              outlined
              prepend-inner-icon="mdi-calendar"
              class="px-xs mb-4"
              color="indianred"
              dense
            ></v-select>
          </v-col>
          <v-btn
            elevation="2"
            style="background-color: maroon; color: white; margin-right: 10px"
            @click="searchDate"
          >
            <v-icon left>fa-sharp fa-solid fa-magnifying-glass</v-icon>Search
          </v-btn>
          <v-btn
            elevation="2"
            style="background-color: maroon; color: white"
            @click="exportData"
          >
            <v-icon left>fa-solid fa-download</v-icon>export
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            class="elevation-3"
            style="margin: 0 10px; width: 100%; color: maroon"
            :loading="loadTable"
            loading-text="Loading..."
            :footer-props="{
              'items-per-page-options': [5],
            }"
          ></v-data-table>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "employee_name", align: "center" },
        { text: "Mobile Number", value: "mobile_number", align: "center" },
        { text: "Designation", value: "designation", align: "center" },
        { text: "Account", value: "account", align: "center" },
        { text: "Device", value: "device_type", align: "center" },
        { text: "Mac Address", value: "mac_address", align: "center" },
        { text: "Purpose", value: "purpose", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "User Type", value: "user_type", align: "center" },
        { text: "Date Expired", value: "date_expired", align: "center" },
        { text: "Decline Remarks", value: "decline_remarks", align: "center" },
        { text: "Config By", value: "config_by", align: "center" },
        { text: "Config Date", value: "config_date", align: "center" },
        {
          text: "Approved/Declined By",
          value: "hod_approved",
          align: "center",
        },
        {
          text: "Date of Approved/Declined",
          value: "hod_approved_date",
          align: "center",
        },
        { text: "Activated By", value: "activated_by", align: "center" },
        { text: "Date Activated", value: "date_activated", align: "center" },
        { text: "Deactivated By", value: "deactivated_by", align: "center" },
        {
          text: "Date Deactivated",
          value: "date_deactivated",
          align: "center",
        },
      ],
      statusArray: [
        "PENDING",
        "APPROVED BY HOD",
        "DECLINED BY HOD",
        "APPROVED BY IT HEAD",
        "DECLINED BY IT HEAD",
        "CONFIGURED BY NOC",
        "DONE",
        "DEACTIVATED",
      ],
      datefrom: "",
      dateto: "",
      searchStatus: "",
      data: [],
      loadTable: false,
    };
  },
  methods: {
    searchDate() {
      var formData = new FormData();
      formData.append("date_from", this.datefrom);
      formData.append("date_to", this.dateto);
      formData.append("search_status", this.searchStatus);
      this.loadTable = true;
      axios.post(this.apiUrl + "/report/request", formData).then((response) => {
        this.data = response.data;
        setTimeout(() => {
          this.loadTable = false;
        }, 1000);
      });
    },
    exportData() {
      var itemsgrouped = [];
      if (this.data.length > 0) {
        itemsgrouped.push([
          "Employee Name",
          "Mobile Number",
          "Designation",
          "Account",
          "Device Type",
          "Mac Address",
          "Purpose",
          "Status",
          "User Type",
          "Date Expired",
          "Decline Remarks",
          "Config By",
          "Config Date",
          "Approved/Declined By",
          "Date of Approved/Declined",
          "Activated By",
          "Date Activated",
        ]);
        for (var i = 0; i < this.data.length; i++) {
          itemsgrouped.push([
            this.data[i].employee_name,
            this.data[i].mobile_number,
            this.data[i].designation,
            this.data[i].account,
            this.data[i].device_type,
            this.data[i].mac_address,
            this.data[i].purpose,
            this.data[i].status,
            this.data[i].user_type,
            this.data[i].date_expired,
            this.data[i].decline_remarks,
            this.data[i].config_by,
            this.data[i].config_date,
            this.data[i].hod_approved,
            this.data[i].hod_approved_date,
            this.data[i].activated_by,
            this.data[i].date_activated,
          ]);
        }

        var CsvString = "";
        itemsgrouped.forEach(function (RowItem, RowIndex) {
          RowItem.forEach(function (ColItem, ColIndex) {
            if (ColItem == null) {
              ColItem = "";
            }
            CsvString += ColItem + ",";
          });
          CsvString += "\r\n";
        });
        CsvString = "data:application/csv," + encodeURIComponent(CsvString);
        var x = document.createElement("A");
        x.setAttribute("href", CsvString);
        x.setAttribute("download", "RequestReports.csv");
        document.body.appendChild(x);
        x.click();
      }
    },
  },
};
</script>
