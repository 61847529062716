// import Vue from "vue";
import VueCookies from "vue-cookies";

export default {
  created: function () {
    const cookieData = VueCookies.get(this.cookieKey);
    this.token = cookieData ? cookieData.token : "";

    if (cookieData) {
      this.userData = cookieData.user;
    }
  },
  data: function () {
    return {
      cookieKey: "wifiaccess", // return value
      apiUrl: "https://wifi-access.sgccserver02.com/backend/api",
      // apiUrl: "http://127.0.0.1/waf-v2/backend/api",
      userData: {},
      token: "",
    };
  },
};
