<style scoped>
.px-xs {
  max-height: 60px;
}

.v-input {
  color: indianred;
}
</style>
<template>
  <v-container class="form-page" style="padding: 0">
    <v-row align="center" justify="center" style="height: 100vh" dense>
      <v-card class="ma-auto" style="border-radius: 10px">
        <v-card-actions style="padding: 35px; display: block">
          <v-img
            alt="SGCC Logo"
            class="shrink mx-auto"
            contain
            src="../assets/wifi_access_v3.png"
            transition="scale-transition"
            width="50vw"
            height="17vh"
          />
          <v-form
            ref="form"
            v-model="isFormValid"
            lazy-validation
            @submit.prevent="submit"
            :loading="loading"
            :disabled="disableForm"
          >
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="employeename"
                    :rules="rules.employeename"
                    label="Employee Name"
                    required
                    outlined
                    prepend-inner-icon="mdi-account"
                    class="px-xs"
                    color="indianred"
                    autofocus
                  ></v-text-field>
                  <v-text-field
                    v-model="designation"
                    :rules="rules.designation"
                    label="Designation"
                    required
                    outlined
                    prepend-inner-icon="mdi-account-check"
                    class="px-xs"
                    color="indianred"
                  ></v-text-field>
                  <!-- <v-text-field
                    v-model="usertype"
                    :rules="rules.usertype"
                    label="Usertype"
                    readonly
                    required
                    outlined
                    prepend-inner-icon="mdi-account-check"
                    class="px-xs "
                    color="indianred"
                  ></v-text-field> -->
                  <!-- <v-select
                    v-model="usertype"
                    :items="['Guest', 'Employee']"
                    :rules="rules.usertype"
                    label="User Type"
                    required
                    outlined
                    prepend-inner-icon="mdi-account-circle"
                    class="px-xs "
                    color="indianred"
                    @change="onChangeUsertype"
                  ></v-select> -->
                  <!-- <v-text-field
                    v-model="dateexpired"
                    :rules="rules.dateexpired"
                    label="Date Expired (if Guest)"                                                                                                                                                                                                                           
                    type="date"
                    required
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    class="px-xs "
                    color="indianred"
                    v-if="employeeType"
                  ></v-text-field> -->
                  <!-- @input="macaddress = macaddress ? macaddress.toUpperCase() : ''" -->
                  <v-text-field
                    v-model="macaddress"
                    :rules="rules.macaddress"
                    label="Device MAC Address"
                    required
                    outlined
                    prepend-inner-icon="mdi-wifi"
                    class="px-xs"
                    color="indianred"
                    v-mask="'NN:NN:NN:NN:NN:NN'"
                    @input="checkMacAddress"
                    ref="mac_address"
                    :disabled="macAddValidation"
                    @keyup="uppercase"
                  ></v-text-field>
                  <v-select
                    v-model="site"
                    :rules="rules.sites"
                    :items="sites"
                    label="Choose Site"
                    required
                    outlined
                    prepend-inner-icon="mdi-account-multiple"
                    class="px-xs"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="mobilenumber"
                    :rules="rules.mobilenumber"
                    label="Mobile Number"
                    required
                    outlined
                    prepend-inner-icon="mdi-cellphone"
                    class="px-xs"
                    color="indianred"
                    v-mask="'(####) ### ####'"
                  ></v-text-field>
                  <v-select
                    v-model="account"
                    :rules="rules.accounts"
                    :items="accounts"
                    label="Please specify your Account"
                    required
                    outlined
                    prepend-inner-icon="mdi-account-multiple"
                    class="px-xs"
                  ></v-select>
                  <v-select
                    v-model="devicetype"
                    :items="['Mobile', 'Laptop']"
                    :rules="rules.devicetype"
                    label="Type of Device"
                    required
                    outlined
                    prepend-inner-icon="mdi-cellphone-link"
                    class="px-xs"
                    color="indianred"
                    @change="onChangeDevicetype"
                  ></v-select>
                  <v-text-field
                    v-model="mobilebrand"
                    :rules="rules.mobilebrand"
                    label="Mobile Brand"
                    required
                    outlined
                    prepend-inner-icon="mdi-cellphone-information"
                    class="px-xs"
                    color="indianred"
                    v-if="deviceType"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    :rules="rules.purpose"
                    required
                    outlined
                    name="purpose"
                    class="px-md"
                    label="Purpose"
                  ></v-textarea>
                </v-col>
                <div class="text-center" style="width: 100%">
                  <!-- <v-btn
                    :disabled="!isFormValid"
                    color="success"
                    class="mr-4"
                    @click="validate"
                  >
                    Validate
                  </v-btn> -->
                  <v-btn
                    color="success"
                    class="mr-4 ma-auto"
                    type="submit"
                    :loading="loading"
                    :disabled="!isFormValid"
                  >
                    Submit
                  </v-btn>
                  <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                    rounded="pill"
                    :color="snackColor ? 'accent' : 'primary'"
                    style="margin-top: 20px"
                    top
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                  <v-btn color="error" class="mr-4 ma-auto" @click="reset">
                    Reset
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    disableForm: false,
    isFormValid: false,
    employeename: "",
    designation: "",
    usertype: "Employee",
    devicetype: "",
    macaddress: "",
    mobilenumber: "",
    account: "",
    dateexpired: "",
    mobilebrand: "",
    purpose: "",
    site: "",
    employeeType: false,
    deviceType: false,
    accounts: [],
    sites: [],
    rules: {
      employeename: [
        (val) => (val || "").length > 0 || "Employee Name is required",
      ],
      designation: [
        (val) => (val || "").length > 0 || "Designation is required",
      ],
      devicetype: [
        (val) => (val || "").length > 0 || "Device Type is required",
      ],
      // usertype: [(val) => (val || "").length > 0 || "User Type is required"],
      macaddress: [
        (val) => !!val || "This field is required",
        (val) =>
          (val && val.length >= 17) ||
          "This field must have atleast 12 characters",
        (val) =>
          (val && val.length < 18) ||
          "This field exceeds maximum allowed characters",
      ],
      mobilenumber: [
        (val) => (val || "").length > 0 || "Mobile Number is required",
      ],
      accounts: [(val) => (val || "").length > 0 || "Account is required"],
      purpose: [(val) => (val || "").length > 0 || "Purpose is required"],
      sites: [(val) => (val || "").length > 0 || "site is required"],
    },
    snackbar: false,
    timeout: 3000,
    snackbarText: "",
    snackColor: true,
    loading: false,
    userAccounts: "",
    macAddValidation: false,
    checkerReset: false,
  }),
  created() {
    this.getAccounts();
    this.getSites();
  },
  methods: {
    getAccounts() {
      axios.get(this.apiUrl + "/getAccounts").then((response) => {
        this.accountsData = response.data;
        this.accounts = [];
        for (let eachApplication of this.accountsData) {
          this.accounts.push(eachApplication.title);
        }
      });
    },
    getSites() {
      axios.get(this.apiUrl + "/getSites").then((response) => {
        this.sitesData = response.data;
        this.sites = [];
        for (let eachApplication of this.sitesData) {
          this.sites.push(eachApplication.title);
        }
      });
    },
    uppercase() {
      this.macaddress = this.macaddress.toUpperCase();
    },
    checkMacAddress() {
      this.checkerReset = false;
      if (this.checkerReset == false) {
        if (this.macaddress == null) {
          this.macAddValidation = false;
          this.isFormValid = true;
        } else if (this.macaddress.length == 17) {
          var formData = new FormData();
          formData.append("mac", this.macaddress);
          axios
            .post(this.apiUrl + "/checkMacAddress", formData)
            .then((response) => {
              if (response.data == 1) {
                this.snackbar = true;
                this.snackbarText = "Mac address already exist!";
                this.macAddValidation = true;
                this.isFormValid = false;
                setTimeout(() => {
                  this.macaddress = "";
                  this.macAddValidation = false;
                  this.isFormValid = true;
                }, 2000);
              }
            });
        }
      }
    },
    submit() {
      // console.log("validate:: ", this.$refs.form.validate());
      this.$refs.form.validate();
      // console.log(this.$refs.form);
      // if (this.$refs.form.value == true) {
      // if (
      //   this.employeename !== "" &&
      //   this.mobilenumber !== "" &&
      //   this.account !== "" &&
      //   this.devicetype !== "" &&
      //   this.macaddress !== "" &&
      //   this.site !== ""
      // )
      this.loading = true;
      this.disableForm = true;
      if (this.$refs.form.value === true) {
        var formData = new FormData();
        formData.append("employee_name", this.employeename);
        formData.append("designation", this.designation);
        formData.append("mobile_number", this.mobilenumber);
        formData.append("account", this.account);
        formData.append("type_of_device", this.devicetype);
        formData.append("mobile_brand", this.mobilebrand);
        formData.append("mac_address", this.macaddress);
        formData.append("date_expired", this.dateexpired);
        formData.append("user_type", this.usertype);
        formData.append("purpose", this.purpose);
        formData.append("site", this.site);
        axios.post(this.apiUrl + "/send/request", formData).then((response) => {
          this.loading = true;
          // console.log("response: ", response);
          if (response.data.status == true) {
            this.snackbar = true;
            this.snackbarText = "Submitted Successfully!";
            this.snackColor = true;

            requestAnimationFrame(() => {
              this.$refs.form.reset();
              this.isFormValid = false;
            });
            setTimeout(() => {
              this.disableForm = false;
              this.loading = false;
            }, 1000);
          } else {
            this.loading = false;
            this.disableForm = false;
            this.snackbar = true;
            this.snackbarText = "Form not submitted!";
            this.snackColor = true;
            this.isFormValid = false;
          }
        });
      }
    },
    onChangeUsertype(selectObj) {
      if (selectObj == "Guest") {
        this.employeeType = true;
      } else {
        this.employeeType = false;
      }
    },
    onChangeDevicetype(selectObj) {
      if (selectObj == "Mobile") {
        this.deviceType = true;
      } else {
        this.deviceType = false;
      }
    },
    reset() {
      this.checkerReset == true;
      this.isFormValid = true;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
