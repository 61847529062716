
import Vue from "vue";
import VueMask from "v-mask";
import vueCookies from "vue-cookies";
import axios from "axios";
Vue.use(VueMask);

export default Vue.extend({
  name: "App",

  data: () => ({
    show1: false,
    show2: false,
    logoutDialog: false,
    loading: false,
    changePassDialog: false,
    oldPassword: "",
    newPassword: "",
    id: "",
    settings: [
      { title: "Change Password", category: "password" },
      { title: "Logout", category: "logout" },
    ],
    change: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
    timeout: 3000,
    passLogoutDialog: false,
  }),
  created() {
    this.id = this.userData.id;
  },
  methods: {
    clickMenu(category) {
      if (category == "password") {
        this.changePassDialog = true;
      } else {
        this.logoutDialog = true;
      }
    },
    submit() {
      this.loading = true;
      var formData = new FormData();
      formData.append("oldPassword", this.oldPassword);
      formData.append("newPassword", this.newPassword);
      formData.append("id", this.id);
      axios
        .post(this.apiUrl + "/send/changepassword", formData)
        .then((response) => {
          if (response.data.status == true) {
            this.snackbar = true;
            this.snackbarText = response.data.message;
            this.snackColor = true;
            this.snackbar = {
              message: response.data.message,
              color: "success",
              show: true,
            };
            this.changePassDialog = false;
            this.passLogoutDialog = true;
          } else {
            this.snackbar = {
              message: response.data.message,
              color: "error",
              show: true,
            };
            this.oldPassword = "";
            this.newPassword = "";
          }
          this.loading = false;
        });
    },
    async logout() {
      this.loading = true;
      vueCookies.remove(this.cookieKey);
      setTimeout(() => {
        location.reload();
        // this.$router.replace({ name: "login" });
        //this.loading = false;
      }, 1000);
    },
  },
});
