<style>
.col-card {
  padding: 30px !important;
}

.col-card-title {
  padding: 20px 30px !important;
  font-size: 20px;
}

h1 {
  text-align: center;
  width: 100%;
  font-size: xxx-large;
  margin-bottom: 10px;
  letter-spacing: 7px;
  color: maroon;
}

.v-col {
  height: 60px;
}
</style>

<template>
  <v-container>
    <v-row align="center" justify="center" style="height: 91vh" dense>
      <v-card class="ma-auto vw-wh" elevation="2" style="padding: 30px">
        <v-row no-gutters>
          <h1>DEPARTMENT HEAD APPROVAL</h1>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="10"
            class="elevation-1"
            style="margin: 0 30px; width: 100%; color: maroon"
            :loading="loadTable"
            loading-text="Loading..."
            :footer-props="{
              'items-per-page-options': [10],
            }"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                rounded="pill"
                :color="snackbarColor ? 'green' : 'red'"
                style="margin-top: 20px"
                top
              >
                {{ snackbarText }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <v-dialog
                v-model="approveDialog"
                persistent
                max-width="350"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="forApproval(item)"
                    fab
                    small
                    class="mx-2"
                  >
                    <v-icon small> fa-solid fa-check </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    class="text-h5 primary"
                    style="color: white; padding-bottom: 15px"
                  >
                    For Approval
                  </v-card-title>
                  <v-card-text
                    class="text-h6 mt-3 text-center"
                    style="color: maroon"
                  >
                    Do you want to <b>APPROVE</b> this request?
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="closeApproval"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="approveRequest(forApprovalData.id)"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Approve
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="declineDialog"
                persistent
                max-width="400"
                :retain-focus="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="forDeclining(item)"
                    fab
                    small
                  >
                    <v-icon small> fa-solid fa-x </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    class="text-h5 primary"
                    style="color: white; padding-bottom: 15px"
                  >
                    Enter your reason for declining
                  </v-card-title>
                  <v-card-text style="margin-top: 20px; padding-bottom: 0">
                    <v-textarea
                      outlined
                      name="reason"
                      label="Reason"
                      v-model="reason"
                      :rules="rules.reason"
                      required
                    ></v-textarea>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="declineDialog = false"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="declineRequest(forDecliningData.id)"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Decline
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <!-- <v-icon small @click="declineRequest(item)">fa-solid fa-x</v-icon> -->
              </v-dialog>
            </template>
          </v-data-table>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      data: [],
      headers: [
        { text: "Name", value: "employee_name", align: "center" },
        { text: "Mobile Number", value: "mobile_number", align: "center" },
        { text: "Designation", value: "designation", align: "center" },
        { text: "Account", value: "account", align: "center" },
        { text: "Device", value: "device_type", align: "center" },
        { text: "Purpose", value: "purpose", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      approveDialog: false,
      declineDialog: false,
      forApprovalData: {
        id: 0,
        account: "",
        designation: "",
        device_type: "",
        employee_name: "",
        mac_address: "",
        mobile_number: "",
        purpose: "",
        status: "",
      },
      forDecliningData: {
        id: 0,
        account: "",
        designation: "",
        device_type: "",
        employee_name: "",
        mac_address: "",
        mobile_number: "",
        purpose: "",
        status: "",
      },
      snackbar: false,
      timeout: 3000,
      loadTable: false,
      loading: false,
      reason: "",
      rules: {
        reason: [(val) => (val || "").length > 0 || "Reason is required"],
      },
      snackbarText: "",
      snackbarColor: false,
      role: "",
      accounts: "",
      fullname: "",
      timer: "",
      approvalAccounts: "",
    };
  },
  created() {
    this.role = this.userData.role;
    this.accounts = this.userData.accounts;
    this.fullname = this.userData.fullname;
    this.approvalAccounts = this.userData.approval_account;
    this.getList();
    this.timer = setInterval(this.getList, 300000);
  },
  methods: {
    getList() {
      this.loadTable = true;
      var formData = new FormData();
      formData.append("role", this.role);
      formData.append("accounts", this.accounts);
      formData.append("approvalAccounts", this.approvalAccounts);
      console.log(this.role);
      console.log(this.accounts);
      console.log(this.approvalAccounts);
      axios.post(this.apiUrl + "/for/approval", formData).then((response) => {
        console.log("ruben test", response.data);
        this.data = response.data;
        setTimeout(() => {
          this.loadTable = false;
        }, 1000);
      });
    },
    forApproval(item) {
      this.forApprovalData = Object.assign({}, item);
      this.approveDialog = true;
    },
    forDeclining(item) {
      this.forDecliningData = Object.assign({}, item);
      this.declineDialog = true;
    },
    closeApproval() {
      this.approveDialog = false;
      this.forApprovalData = {};
    },
    async approveRequest(id) {
      var formData = new FormData();
      formData.append("id", id);
      formData.append("reason", "");
      formData.append("status", "APPROVED BY HOD");
      formData.append("hod", this.role);
      formData.append("account", this.accounts);
      formData.append("current", "HOD");
      formData.append("fullname", this.fullname);
      await axios.post(this.apiUrl + "/approved", formData).then((response) => {
        if (response.status == 200) {
          this.snackbar = true;
          this.snackbarText = "Successfully Approved";
          this.snackbarColor = "green";
          this.loading = false;
          this.approveDialog = false;
          this.getList();
        }
      });
    },
    async declineRequest(id) {
      var formData = new FormData();
      formData.append("id", id);
      formData.append("reason", this.reason);
      formData.append("status", "DECLINED BY HOD");
      formData.append("hod", this.role);
      formData.append("account", this.accounts);
      formData.append("current", "HOD");
      formData.append("fullname", this.fullname);
      await axios.post(this.apiUrl + "/declined", formData).then((response) => {
        if (response.status == 200) {
          this.snackbar = true;
          this.snackbarText = "Successfully Declined";
          this.snackbarColor = "accent";
          this.loading = false;
          this.declineDialog = false;
          this.getList();
        }
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
};
</script>
